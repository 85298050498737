<template>
  <div class="flex flex-col justify-between h-screen">
    <div
      class="flex items-center content-center h-screen text-white bg-[#0f0f0f] bg-no-repeat bg-cover bg-center"
      style="background-image: url('/Error404.jpg')"
    >
      <div
        class="absolute inset-0 bg-[#0f0f0f] bg-opacity-50 mix-blend-multiply z-10"
      />
      <div class="relative z-20 pt-20 mx-auto space-y-8 text-center max-w-7xl">
        <div>
          <div class="flex items-center justify-center">
            <Svg404 class="w-40 md:w-auto text-primary" />
          </div>

          <div class="min-h-[80px]" v-if="store">
            <client-only>
              <h1
                v-if="!store.isLoading"
                class="w-full max-w-xl px-10 mx-auto text-xl font-bold text-center md:text-3xl xl:text-4xl md:px-0 font-myriad-pro-condensed"
              >
                {{ $t("common.page_not_found_homepage") }}
              </h1>
              <a
                v-if="!store.isLoading"
                :href="defaultRoute"
                class="inline-block mt-5 text-lg font-normal uppercase transition-all duration-200 text-primary hover:no-underline font-myriad-pro"
              >
                {{ $t("common.return_to_home") }}
              </a>
            </client-only>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Svg404 from "~/components/404NotFound.vue";
import { useMainStore } from "@/store/index";

const props = defineProps({
  error: Object,
});
const nuxtApp = useNuxtApp();
const store = useMainStore();
const route = useRoute();

const defaultRoute = ref(null);

// onServerPrefetch(async () => {
try {
  if (Object.keys(route.params).length) {
    const [lastSelectedCountry, lastSelectedLocale] =
      route.params.country.split("-");

    if (!lastSelectedCountry || lastSelectedCountry == undefined) {
      await store.loadTranslationJsonData("int-en");
      defaultRoute.value = "/int-en";
    } else {
      await updateCall(lastSelectedCountry, lastSelectedLocale);
    }
  } else if (route.fullPath && route.fullPath.split("/").length > 1) {
    const routeCountrySlug = route.fullPath.split("/")[1];
    if (routeCountrySlug.includes("-")) {
      const [lastCountry, lastLocale] = routeCountrySlug.split("-");

      if (!lastCountry || lastLocale == undefined) {
        await store.loadTranslationJsonData("int-en");
        defaultRoute.value = "/int-en";
      } else {
        await updateCall(lastCountry, lastLocale);
      }
    } else {
      defaultRoute.value = "/int-en";
      await store.loadTranslationJsonData("int-en");
    }
  } else {
    defaultRoute.value = "/int-en";
    await store.loadTranslationJsonData("int-en");
  }
} catch (e) {
  console.log(e);
}
// });

async function updateCall(lsc, lsl) {
  const { data } = await useAPI("api/sites");

  // Check if the stored country in cookie exists as an oficial site
  const previousSite = data.value.find((country) => country.slug === lsc);

  if (previousSite) {
    const redirectUrlLocale = previousSite.urls.find(
      (locale) => locale.locale_code === lsl
    );

    if (
      !redirectUrlLocale &&
      previousSite &&
      previousSite.urls.length &&
      previousSite.urls[0]
    ) {
      await store.loadTranslationJsonData(
        lsl + "-" + previousSite.urls[0].locale_code
      );
      defaultRoute.value = previousSite.urls[0].url;
    } else {
      await store.loadTranslationJsonData(lsc + "-" + lsl);
      defaultRoute.value = redirectUrlLocale.url;
    }
  } else {
    defaultRoute.value = "/int-en";
    await store.loadTranslationJsonData("int-en");
  }
}
definePageMeta({
  layout: "blank",
});
</script>
